import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import { IEditorSDK } from 'yoshi-flow-editor-runtime/tpa-settings';
import { TranslationFunction, EditorReadyFn } from 'yoshi-flow-editor-runtime';
import { CustomActionConfig, EditorPlatformApp } from '@wix/platform-editor-sdk';
import webBiLogger from '@wix/web-bi-logger';
import initSchemaLogger, { Logger } from '@wix/bi-logger-membership';

import { setPermissionsPage } from './utils/setPermissionsPage';
import { appDefinitionId as PRICING_PLANS_APP_DEF_ID } from '../.application.json';
import { id as planListWidgetId } from './components/PlanList/.component.json';
import { id as ppWidgetId } from './components/PackagePicker/.component.json';

const ACTION_APP_INSTALLED = 'appInstalled';

let appDefinitionId: string;
let editorSDK: IEditorSDK;
let flowAPI: Parameters<EditorReadyFn>[3];
let translate: TranslationFunction;
let biLogger: Logger;

const _editorReady: EditorReadyFn = async (sdk, appDefId, _options, _flowAPI) => {
  appDefinitionId = appDefId;
  editorSDK = sdk;
  flowAPI = _flowAPI;
  translate = _flowAPI.translations.t;
  biLogger = initSchemaLogger(webBiLogger)();

  // @todo: sarunas - IMHO this should be removed, cause no need each time set custom error page (or is it?)
  await trySetPermissionsPage();
};

const _handleAction: EditorPlatformApp['handleAction'] = async ({ type, payload }) => {
  if (
    type === ACTION_APP_INSTALLED &&
    (payload as Record<string, string>).appDefinitionId === PRICING_PLANS_APP_DEF_ID
  ) {
    await trySetPermissionsPage();
  }
};

const trySetPermissionsPage = async () => {
  try {
    await setPermissionsPage(editorSDK, appDefinitionId);
  } catch (e) {
    flowAPI.reportError(e);
  }
};

const platformApp = withMembersArea(
  {
    editorReady: _editorReady,
    handleAction: _handleAction,
  },
  {
    disableADI: false,
    membersAreaApps: [MA_APP_IDS.MY_SUBSCRIPTIONS],
  },
);

export const editorReady = platformApp.editorReady;
export const handleAction = platformApp.handleAction;

export const getAppManifest: EditorPlatformApp['getAppManifest'] = async () => {
  const experiments = await flowAPI.getExperiments();

  const buildCustomActions = () => {
    const result: CustomActionConfig[] = [];
    result.push({
      title: translate('manage-apps-panel.manage-purchases'),
      actionId: 'openManagePurchases',
      icon: 'appManager_settingsAction',
      type: 'dashboard',
    });
    result.push({
      title: translate('manage-apps-panel.customize-plans-page'),
      icon: 'appManager_pagesAction',
      actionId: 'openPricingPlansSettings',
      type: 'editorActions',
    });
    if (experiments.enabled('specs.membership.ShowPlanListWidget')) {
      result.push({
        title: translate('manage-apps-panel.add-pricing-plans-elements'),
        icon: 'appManager_addElementsAction',
        actionId: 'addPricingPlansElements',
        type: 'editorActions',
      });
    }
    result.push({
      title: translate('manage-apps-panel.manage-email-automations'),
      icon: 'email_icon',
      actionId: 'openMyAutomations',
      type: 'dashboard',
    });
    return result;
  };

  return {
    routers: undefined, // not in this article, not needed in a new manifest
    controllersStageData: {
      [planListWidgetId]: {
        default: {
          displayName: translate('manifest.plan-list-widget.display-name'),
        },
      },
    },
    appDescriptor: {
      mainActions: [
        {
          title: translate('manage-apps-panel.manage-plans'),
          actionId: 'openManagePlans',
          icon: 'appManager_settingsAction',
        },
        {
          title: translate('manage-apps-panel.create-new-plan'),
          actionId: 'createNewPlan',
          icon: 'appManager_addElementsAction',
        },
      ],
      customActions: buildCustomActions(),
      defaultActions: {
        upgrade: {
          upgradeType: 'SITE_UPGRADE',
          upgradeText: translate('manage-apps-panel.upgrade-text'),
          upgradeLinkText: translate('manage-apps-panel.upgrade-link-text'),
        },
        learnMoreKB: 'b1d66914-9073-43d1-af55-f923aac621b0',
      },
    },
  };
};

function getSettingsUrl(data: any): string {
  try {
    return data.widgets[ppWidgetId].settings.url;
  } catch {
    return data.settingsUrl;
  }
}

const openPricingPlansSettings = async ({ openAddonsPanel = false } = {}) => {
  await biLogger.pricingPlansSettingsClick({
    appInstanceId: await editorSDK.info.getAppInstanceId(appDefinitionId),
    biToken: await editorSDK.info.getMetaSiteId(appDefinitionId),
  });

  const locale = await editorSDK.environment.getLocale(appDefinitionId);
  const currency = await editorSDK.info.getCurrency(appDefinitionId);
  const data = await editorSDK.tpa.app.getDataByAppDefId(appDefinitionId, appDefinitionId);
  const components = await editorSDK.tpa.app.getAllCompsByApplicationId(appDefinitionId, data.applicationId);
  if (components && components.length) {
    const component = components.filter((c) => c.type === 'TPA')[0];
    if (component) {
      const componentRef = await editorSDK.components.getById(appDefinitionId, { id: component.id });
      await editorSDK.document.pages.navigateTo(appDefinitionId, {
        pageLink: { type: 'PageLink', pageId: component.pageId },
      });
      const settingsUrl = getSettingsUrl(data);
      await editorSDK.editor.openComponentPanel(appDefinitionId, {
        url:
          settingsUrl +
          `?instance=${data.instance}&appDefinitionId=${data.appDefinitionId}&applicationId=${data.applicationId}&compId=tpaSettings&currency=${currency}&endpointType=settings&locale=${locale}&origCompId=${component.id}&viewMode=editor&width=404&referralInfo=manage_app_panel` +
          (openAddonsPanel ? '&openAddonsPanel=true' : ''),
        type: editorSDK.editor.PanelType.Settings,
        componentRef,
        width: 404,
        height: 528,
        title: 'Plans & Pricing',
      });
    }
  }
};

export const onEvent: EditorPlatformApp['onEvent'] = async ({ eventType, eventPayload }) => {
  if (eventType === 'appActionClicked') {
    const { actionId } = eventPayload;
    if (actionId === 'openManagePlans') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/membership/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'createNewPlan') {
      await biLogger.createPlanClick({
        appInstanceId: await editorSDK.info.getAppInstanceId(appDefinitionId),
        biToken: await editorSDK.info.getMetaSiteId(appDefinitionId),
        formOrigin: 'null',
        referralInfo: 'manage_app_panel',
      });
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/membership/setup?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openManagePurchases') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/subscriptions/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openPricingPlansSettings') {
      await openPricingPlansSettings();
    } else if (actionId === 'addPricingPlansElements') {
      await openPricingPlansSettings({ openAddonsPanel: true });
    } else if (actionId === 'openMyAutomations') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/triggers/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    }
  }
};
